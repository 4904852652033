import React from 'react'

export default props => (
  <svg
    fill="currentColor"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    style={{ verticalAlign: 'middle' }}
    {...props}
  >
    <g>
      <path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z" />
    </g>
  </svg>
)
